<template>
    <v-dialog
            persistent
            v-model="viewDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="750px"
            min-width="600px"
            max-width="750px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.totals') }}</v-card-title>
            <v-card-text>
                <v-simple-table dense class="mt-3 appic-table-light specification-table" fixed-header height="400">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-right pa-1 font-weight-bold amber lighten-3" colspan="5">{{ $t('message.total') }}</th>
                                <th class="text-right pa-1 font-weight-bold amber lighten-3">{{ formatThisNumber(totalCurrentBuyingPriceLocal, '0,0') }}</th>
                                <th class="text-start pa-1 font-weight-bold amber lighten-3">{{ localCurrency }}</th>
                            </tr>
                            <tr>
                                <th class="text-start light-green lighten-3 pa-1">{{ $t('message.product') }}</th>
                                <th class="text-start light-green lighten-3 pa-1">{{ $t('message.claim') }}</th>
                                <th class="text-start light-green lighten-3 pa-1">{{ $t('message.species') }}</th>
                                <th class="text-right light-green lighten-3 pa-1">{{ $t('message.qty') }}</th>
                                <th class="text-start light-green lighten-3 pa-1">{{ $t('message.unit') }}</th>
                                <th class="text-right light-green lighten-3 pa-1">{{ $t('message.currentBuyingPriceLocal') }}</th>
                                <th class="text-start light-green lighten-3 pa-1">{{ $t('message.cur') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="total in totals">
                                <td class="text-start pa-1">{{ total.product }}</td>
                                <td class="text-start pa-1">{{ total.certificate }}</td>
                                <td class="text-start pa-1">{{ total.species }}</td>
                                <td class="text-right pa-1">{{ formatThisNumber(total.total, total.uofm == 'M2' ? '0,0.00' : '0,0.000') }}</td>
                                <td class="text-start pa-1">{{ total.uofm }}</td>
                                <td class="text-right pa-1">{{ formatThisNumber(total.totalCurrentBuyingPriceLocal, '0,0') }}</td>
                                <td class="text-start pa-1">{{ total.localCurrency }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-chip v-for="grossTotal in grossTotals" :key="'gt_' + grossTotal.uofm" class="mr-1 mt-4 mb-0 pa-2">
                    {{ grossTotal.uofm + " = " + formatThisNumber(grossTotal.total, '0,0.000')}}
                </v-chip>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" @click="viewDialog = false" >{{$t('message.dismiss')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {numberFormat} from "Helpers/helpers";
import {mapGetters} from "vuex";

export default {
    name: "AvailableListTotals",
    props: ['totals','dialog'],
    data(){
        return {
            view_dialog: false,
            grossTotals: [],
            localCurrency: 'USD',
            totalCurrentBuyingPriceLocal: 0,
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        viewDialog: {
            get() {
                return this.view_dialog;
            },
            set(value){
                this.view_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
    },
    watch: {
        dialog(value) {
            this.view_dialog = value
            if(value){
                this.totalCurrentBuyingPriceLocal = 0;
                this.localCurrency = 1;

                //sort
                this.totals.sort((a, b) => {
                    const productA = a.product ? a.product.toUpperCase() : 'ZZZZZZZZZZ'
                    const productB = b.product ? b.product.toUpperCase() : 'ZZZZZZZZZZ'
                    if(productA < productB ){
                        return -1
                    } else if(productA > productB ){
                        return 1
                    } else {
                        const certificateA = a.certificate ? a.certificate.toUpperCase() : 'ZZZZZZZZZZ'
                        const certificateB = b.certificate ? b.certificate.toUpperCase() : 'ZZZZZZZZZZ'
                        if(certificateA < certificateB){
                            return -1
                        } else if(certificateA > certificateB ){
                            return 1
                        } else {
                            const speciesA = a.species ? a.species.toUpperCase() : 'ZZZZZZZZZZ'
                            const speciesB = b.species ? b.species.toUpperCase() : 'ZZZZZZZZZZ'
                            if(speciesA < speciesB) {
                                return -1
                            } else if(speciesA > speciesB ) {
                                return 1
                            }
                            return 0
                        }
                    }
                })

                //get some totals
                this.totals.forEach(total => {
                    const checkIndex = this.grossTotals.findIndex(grossTotal => {
                        return grossTotal.uofm == total.uofm
                    })
                    if(checkIndex >= 0){
                        this.grossTotals[checkIndex]['total'] += total.total
                    } else {
                        this.grossTotals.push({
                            uofm: total.uofm,
                            total: total.total
                        })
                    }

                    this.totalCurrentBuyingPriceLocal += total.totalCurrentBuyingPriceLocal;
                    this.localCurrency = total.localCurrency;
                })
            }
        }
    },
}

</script>

<style scoped>
    table .text-start {
        font-size: 0.875rem !important;
    }
    table .text-right {
        font-size: 0.875rem !important;
    }
</style>